<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-checkbox
            :editable="editable"
            :isFlag="true"
            label="LBLUSEFLAG"
            name="useFlag"
            v-model="searchParam.useFlag"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="equipTable"
      title="문제풀이 SET 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      :columnSetting="false"
      :isExcelDown="false"
      :isFullScreen="false"
      rowKey="eduQuestionMstId"
      @linkClick="linkClick"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'quiz-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          useFlag: 'Y',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'educationKindCdLargeName',
            field: 'educationKindCdLargeName',
            label: '교육종류',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'eduQuestionMstName',
            field: 'eduQuestionMstName',
            label: '문제풀이 SET 명',
            align: 'left',
            type: "link",
            sortable: true,
          },
          // {
          //   name: 'eduQuestionPassScore',
          //   field: 'eduQuestionPassScore',
          //   label: '시험통과 기준점수',
          //   type: 'cost',
          //   sortable: true,
          // },
        ],
        data: [],
        height: '450px'
      },
      searchParam: {
        useFlag: 'Y',
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 450;
      }
      this.grid.height = height + 'px'
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.edu.quiz.mst.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['equipTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '문제풀이 SET를 선택하세요.', // 설비를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    linkClick(row) {
      this.popupOptions.title = '교육 문제풀이 SET 상세'; // 설비 검색
      this.popupOptions.param = {
        eduQuestionMstId: row ? row.eduQuestionMstId : '',
        disableEidt: false,
      };
      this.popupOptions.target = () => import(`${"@/pages/sop/edu/quiz/questionMstDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '95%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
